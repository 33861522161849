import React from 'react';
import { Shortcuts } from '@atlassian/jira-common-components-keyboard-shortcuts';

const QUIT_WALLBOARD_SHORTCUT = 'escape';

type Props = {
	onPressedEsc: () => void;
};

const KeyboardShortcuts = (props: Props) => {
	const keyMap = {
		[QUIT_WALLBOARD_SHORTCUT]: {
			callback: props.onPressedEsc,
		},
	};

	return <Shortcuts keyMap={keyMap} />;
};

export default KeyboardShortcuts;
