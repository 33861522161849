import { useEffect, useState } from 'react';
import type { DashboardConfigAndWRMResourceData } from '@atlassian/jira-dashboard-common';
import { useMultiDashboardsResource } from '@atlassian/jira-dashboard-internal-common';
import { useQueryParam, type RouteResourceError } from '@atlassian/jira-router';

const nextIndex = (prevIndex: number, maxLength: number) => (prevIndex + 1) % maxLength;

const randomiseIfApplicable = (
	random: string | undefined,
	data: DashboardConfigAndWRMResourceData[],
): DashboardConfigAndWRMResourceData[] => {
	if (random === 'true' && data) {
		const result = [...data];
		result.sort(() => Math.random() - 0.5);
		return result;
	}
	return data;
};

const DEFAULT_CYCLE_PERIOD = 30000;
const safeParseCyclePeriodOrDefault = (cyclePeriod: string) => {
	try {
		// The interval is stored in a signed 32-bit int so any number above 2**31-1 would
		// become negative which will cause the callback to infinitely loop without any delay.
		// https://stackoverflow.com/a/12633556/1104483
		return Math.min(Number.parseInt(cyclePeriod, 10), Math.pow(2, 31) - 1);
	} catch (e) {
		return DEFAULT_CYCLE_PERIOD;
	}
};

export const useDashboardsWallboardSlideShow = (): {
	data: DashboardConfigAndWRMResourceData[] | null;
	currentDashboardIndex: number;
	error: RouteResourceError | null;
	loading: boolean;
} => {
	const [cyclePeriod] = useQueryParam('cyclePeriod');
	const [random] = useQueryParam('random');
	const { data, error, loading } = useMultiDashboardsResource();
	const [dashboards, setDashboards] = useState<DashboardConfigAndWRMResourceData[]>([]);

	const [currentIndex, setIndex] = useState<number>(0);
	useEffect(() => {
		if (cyclePeriod && dashboards) {
			if (dashboards.length < 2) {
				// there is only one dashboard
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				return () => {};
			}
			// start wallboard slide show
			const interval = setInterval(() => {
				setIndex((prev: number): number => nextIndex(prev, dashboards.length));
			}, safeParseCyclePeriodOrDefault(cyclePeriod));
			return () => clearInterval(interval);
		}
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	}, [dashboards, cyclePeriod]);

	useEffect(() => {
		// Randomises and sets the list of dashboards for slide show on initialisation. This call should
		// only happen once unless data or random is changed e.g. a page reload or url change
		if (data && data?.length > 0) {
			setDashboards(randomiseIfApplicable(random, data));
		}
	}, [data, random]);

	// We create a new error object as the endpoint for wallboard slide show does not return any error(s)
	// if the user cannot see a dashboard. Instead, if the response data returns 0 dashboards, it indicates the user has provided
	// invalid dashboard ids and we will show an error page by instantiating a new error here
	if (data?.length === 0 && !loading) {
		return {
			data: null,
			// "401" has been intentionally left as a string in this error message as this will cause a wallboard permission error page to render
			// It did previously utilise DASHBOARD_PERMISSION_ERROR_CODE_LIST instead, but there seems to be import issues and it is not working as expected.
			// A hardcoded string will have to do for now.
			error: new Error('No dashboards could load on wallboard: 401'),
			currentDashboardIndex: currentIndex,
			loading,
		};
	}

	return {
		data: dashboards,
		currentDashboardIndex: currentIndex,
		error,
		loading,
	};
};
