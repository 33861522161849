import React from 'react';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { Stack, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { GadgetFallback } from '@atlassian/jira-dashboard-internal-common';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export const UnconfiguredGadgetWallboardFallback = ({ id }: { id: string }) => {
	const { formatMessage } = useIntl();

	return (
		<GadgetFallback
			icon={
				<SettingsIcon
					label={formatMessage(messages.settingsIconLabel)}
					primaryColor={token('color.icon', colors.N500)}
				/>
			}
			id={id}
		>
			<Stack space="space.150">
				<Text size="UNSAFE_small" weight="semibold" color="color.text.accent.gray">
					{formatMessage(messages.mainMessage)}
				</Text>
				<Text size="UNSAFE_small" weight="semibold" color="color.text.accent.gray">
					{formatMessage(messages.configureInstructions)}
				</Text>
			</Stack>
		</GadgetFallback>
	);
};
