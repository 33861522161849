import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	mainMessage: {
		id: 'dashboard-wallboard.unconfigured-gadget-wallboard-fallback.main-message',
		defaultMessage: 'Gadget is not configured',
		description:
			'Describing to the user that we could not show a view for this gadget because it is yet to be configured.',
	},
	configureInstructions: {
		id: 'dashboard-wallboard.unconfigured-gadget-wallboard-fallback.configure-instructions',
		defaultMessage: 'A dashboard editor needs to configure the gadget in Jira.',
		description: 'Instructions for the user to configure the gadget in dashboard embed.',
	},
	settingsIconLabel: {
		id: 'dashboard-wallboard.unconfigured-gadget-wallboard-fallback.settings-icon-label',
		defaultMessage: 'Settings',
		description: 'An accessible label for a presentational icon.',
	},
});
