import type { GadgetData } from '@atlassian/jira-dashboard-common';

// this is to support a legacy wallboard feature. See https://www.atlassian.com/blog/jira-software/3-steps-to-a-rocking-agile-wallboard
export const groupByColor = (gadgets: GadgetData[]): Array<GadgetData[]> => {
	const map = new Map<string, GadgetData[]>();
	gadgets.forEach((gadget) => {
		const { color } = gadget;
		const collection = map.get(color);
		if (!collection) {
			map.set(color, [gadget]);
		} else {
			collection.push(gadget);
		}
	});
	return Array.from(map.values());
};
